import { useEffect } from 'react';
import ReactGA from "react-ga4";

export default function AppWrapper() {
	useEffect(() => {
		if (process.env.NEXT_PUBLIC_NODE_ENV !== 'development') {
			(async () => {
				try {
					if (!window.GA_INITIALIZED) {
						window.addEventListener('load', () => {
							ReactGA.initialize(process.env.NEXT_PUBLIC_GOOGLE_TRACKING_ID);
							ReactGA.send({ hitType: "pageview", page: window.location.pathname });
							window.GA_INITIALIZED = true;
						})
					}
					return;
				} catch (err) {
					return
				}
			})()
		}
	}, [])

	return null;
}