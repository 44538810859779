import 'styles/globals.scss';
// import { RecoilRoot } from 'recoil';
import AppWrapper from 'components/AppWrapper';
import Layout from 'components/Layout';
import ErrorPage from 'next/error'
// import { fetchCms } from 'lib/api';

export default function MyApp({ Component, pageProps, status }) {

  	return (
		<>
			{ !status ? (
				<>
					{/* <RecoilRoot> */}
						<AppWrapper />
						<Layout>
							<Component {...pageProps} />
						</Layout>
					{/* </RecoilRoot> */}
				</>
			) : (
				<ErrorPage statusCode={404} />
			) } 
		</>
 	)
}

// MyApp.getInitialProps = async () => {
//   const results = await fetchCms(`
// 		query {
// 			listWifigenAdmins {
// 				data {
// 					status
// 				}
// 			}
// 		}
//   `)
//   if (!results) return { status: true }
//   return { status: results.data.listWifigenAdmins.data[0].status }
// }
