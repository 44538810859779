import Head from 'next/head';
// import Navbar from 'components/Navbar';
// import Footer from 'components/Footer';

export default function Layout({ children }) {

	return (
		<>
			<Head>
				<meta charSet="UTF-8" />
    			<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta name="msapplication-TileColor" content="#da532c" />
				<meta name="theme-color" content="#ffffff" />
				<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
				<link rel="manifest" href="/site.webmanifest" />
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
				<link rel="icon" href="/favicon.ico" />
				<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Bitter:400,700&display=swap" />

      	</Head>
			{/* <Navbar /> */}
			<main>
				{ children }
			</main>
			{/* <Footer /> */}
		</>
	)
}
